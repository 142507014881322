import { splitProps, getSlotCompoundVariant } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const headingDefaultVariants = {
  "level": "page"
}
const headingCompoundVariants = []

const headingSlotNames = [
  [
    "root",
    "heading__root"
  ],
  [
    "title",
    "heading__title"
  ],
  [
    "actions",
    "heading__actions"
  ]
]
const headingSlotFns = /* @__PURE__ */ headingSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, headingDefaultVariants, getSlotCompoundVariant(headingCompoundVariants, slotName))])

const headingFn = (props = {}) => {
  return Object.fromEntries(headingSlotFns.map(([slotName, slotFn]) => [slotName, slotFn(props)]))
}

const headingVariantKeys = [
  "level"
]

export const heading = /* @__PURE__ */ Object.assign(headingFn, {
  __recipe__: false,
  __name__: 'heading',
  raw: (props) => props,
  variantKeys: headingVariantKeys,
  variantMap: {
  "level": [
    "page",
    "section"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, headingVariantKeys)
  },
})