import { splitProps, getSlotCompoundVariant } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const inputFieldDefaultVariants = {}
const inputFieldCompoundVariants = []

const inputFieldSlotNames = [
  [
    "root",
    "field__root"
  ],
  [
    "input",
    "field__input"
  ],
  [
    "label",
    "field__label"
  ],
  [
    "error",
    "field__error"
  ],
  [
    "overlayContainer",
    "field__overlayContainer"
  ],
  [
    "menuItem",
    "field__menuItem"
  ]
]
const inputFieldSlotFns = /* @__PURE__ */ inputFieldSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, inputFieldDefaultVariants, getSlotCompoundVariant(inputFieldCompoundVariants, slotName))])

const inputFieldFn = (props = {}) => {
  return Object.fromEntries(inputFieldSlotFns.map(([slotName, slotFn]) => [slotName, slotFn(props)]))
}

const inputFieldVariantKeys = []

export const inputField = /* @__PURE__ */ Object.assign(inputFieldFn, {
  __recipe__: false,
  __name__: 'inputField',
  raw: (props) => props,
  variantKeys: inputFieldVariantKeys,
  variantMap: {},
  splitVariantProps(props) {
    return splitProps(props, inputFieldVariantKeys)
  },
})